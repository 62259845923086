import React, {useMemo} from 'react';
import {prepareAnchorId} from '../util/format';
import Link from 'next/link';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import {Button} from '@/components/ui/button';
import {Typography} from '@/components/ui/typography';
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar';
import {cn} from '@/lib/utils';

type Group = {
    value: string;
    label: string;
}

type TeamMember = {
    name: string;
    role: string;
    description: string;
    image: string;
    group: Group[];
    link: string;
    text?: string;
}

type TeamSectionProps = {
    members: TeamMember[];
    title: string;
    anchor: string;
}

const TeamSection = (props: TeamSectionProps) => {
    if (!props.members || !props.members.length) {
        return null
    }

    const groupedMembers = useGroupedMembers(props.members);
    const anchorId = prepareAnchorId(props.anchor);
    const allGroups = Object.keys(groupedMembers);

    return (
        <div className="container my-32" id={anchorId}>
            <Typography className="text-center text-sm tracking-widest uppercase leading-relaxed font-bold">
                casehub team
            </Typography>
            <Typography variant="h2" className="text-center pb-8 border-0">
                Meet your coaches and facilitators
            </Typography>

            <Tabs defaultValue="coach" className="w-full">
                <div className="flex items-center justify-center mb-6">
                    {
                        allGroups.length > 1 && (
                            <TabsList className="grid grid-cols-2 inline-flex">
                                {
                                    Object.entries(groupedMembers).map(([group, members]) => {
                                        const groups = Array.isArray(members[0].group) ? members[0].group : [members[0].group];
                                        const label = groups?.find(it => it.value === group)?.label;
                                        return <TabsTrigger value={group} key={label + '_' + group}>{label}</TabsTrigger>
                                    })
                                }
                            </TabsList>
                        )
                    }
                </div>
                {
                    Object.entries(groupedMembers).map(([group, members]) => {
                        return (
                            <TabsContent value={group} key={group}>
                                <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                                    {members.map(it => {
                                        return <Member
                                            key={`${it.name}_${it.group[0].value}`}
                                            name={it.name}
                                            image={it.image}
                                            text={it.text}
                                            description={it.description}
                                            link={it.link}
                                        />
                                    })}
                                </div>
                            </TabsContent>
                        )
                    })
                }
            </Tabs>
        </div>
    )
};

const useGroupedMembers = (members: TeamMember[]) => {
    const groupedMembers: {
        [key: string]: TeamMember[];
    } = useMemo(() => {
        const filteredMembers = members.filter(it => it.name);

        return filteredMembers.reduce((acc, it) => {
            const groups = Array.isArray(it.group) ? it.group : [it.group];

            const addToGroup = (group, member) => {
                if (!acc[group]) {
                    acc[group] = [];
                }
                acc[group].push(member);
            };

            for (let group of groups) {
                if (!group) {
                    continue;
                }
                addToGroup(group.value ? group.value : group, it);
            }

            return acc;
        }, {});
    }, [members]);

    return groupedMembers;
};

type MemberProps = {
    name: string;
    image: string;
    text?: string;
    description: string;
    link: string;
}

const MemberWrapper = ({link, children, className}: {
    link: string;
    children: React.ReactNode;
    className?: string;
}) => {
    if (!link) {
        return <div className={className}>
            {children}
        </div>;
    }

    return (
        <Link passHref href={link}>
            <a className={cn('cursor-pointer', className)} target={'_blank'}>
                {children}
            </a>
        </Link>
    )
}

const Member = ({
    name,
    image,
    text,
    description,
    link,
}: MemberProps) => {
    const initials = name.split(' ').map(it => it[0]).join('');
    return (
        <MemberWrapper link={link} className={cn("group rounded-lg shadow-lg bg-teal-50 border-[1px] border-teal-300 p-4 relative overflow-hidden min-h-[180px]", {
            'cursor-pointer': !!link,
        })}>
            <div className="flex gap-1 absolute inset-0 bg-teal-200 group-hover:h-20 group-hover:bg-transparent p-4">
                <Avatar className="w-[148px] h-[148px] aspect-square group-hover:h-12 group-hover:w-12 transition-all duration-300 rounded-[8px] group-hover:rounded-[40px] border-transparent group-hover:border-avatarBorder">
                    <AvatarImage src={image} />
                    <AvatarFallback>{initials}</AvatarFallback>
                </Avatar>
                {!!name ? (
                    <div className="self-end">
                        <p className="text-sm font-medium leading-none">
                            {name}
                        </p>
                        <p className="text-sm text-muted-foreground text-xs">{text}</p>
                    </div>
                ) : null}
            </div>
            <div className="pt-7">
                <Typography variant="blockquote" className="border-teal-200 pl-2 line-clamp-4">
                    {description}
                </Typography>
            </div>
        </MemberWrapper>
    )

    return (
        <Card className="animate-in slide-in-from-right-8">
            <CardHeader className="rounded-lg overflow-hidden px-0 pt-0">
                <img src={image} alt={name} />
            </CardHeader>
            <CardContent className="space-y-2">
                <CardTitle>
                    {name}
                </CardTitle>
                <CardDescription>
                    <span className="block">{text}</span>
                    {description}
                </CardDescription>
                {link && (
                    <Link href={link} passHref>
                        <Button asChild variant="ghost">
                            <a>Read more</a>
                        </Button>
                    </Link>
                )}
            </CardContent>
        </Card>
    )
}
export default TeamSection;
